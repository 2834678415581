.scroll-character {
    position: fixed;
    bottom: -100px;
    right: 10px;
    visibility: hidden;
    opacity: 0;
    z-index: 1000;
    cursor: pointer;
    transition: visibility 0.5s, opacity 0.5s, bottom 0.5s;
}

.scroll-character.show {
    visibility: visible;
    opacity: 1;
    bottom: 5px;
    animation: jump-up 0.5s ease-in-out forwards;
}

@keyframes jump-up {
    0% {
        bottom: -100px;
    }

    50% {
        bottom: 20px;
    }

    100% {
        bottom: 5px;
    }
}