html {
    scroll-behavior: smooth;
}

.App {
    text-align: left;
}

.App-header {
    margin-top: 50px;
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
}

@media (max-width: 768px) {
    .hstack {
        display: flex;
        flex-direction: column;
    }

    .education-container {
        padding-bottom: 50px;
    }
}

.project-container {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.project-container.loaded {
    opacity: 1;
}

.grow-on-hover {
    transition: transform 0.3s ease;
}

.grow-on-hover:hover {
    animation: grow 0.2s linear forwards;
}

.grow-on-hover:not(:hover) {
    animation: shrink 0.2s ease forwards;
}

@keyframes grow {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.05);
    }
}

@keyframes shrink {
    from {
        transform: scale(1.05);
    }

    to {
        transform: scale(1);
    }
}

.jumping-text {
    animation: jump 2.5s infinite alternate, rotate 5s infinite linear;
}

@keyframes jump {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(150px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(15deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(-15deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.rainbow {
    text-align: center;
    font-size: 32px;
    font-family: monospace;
}

.rainbow_text_animated {
    background: linear-gradient(to right, #6666ff, #0099ff, #00ff00, #ff3399, #6666ff) !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
    animation: rainbow_animation 3s ease-in-out infinite !important;
    background-size: 400% 100% !important;
}

@keyframes rainbow_animation {

    0%,
    100% {
        background-position: 0 0 !important;
    }

    50% {
        background-position: 100% 0 !important;
    }
}